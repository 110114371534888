import {resolveAnimation} from './helpers.js';

import '../scss/main.scss';

const loadingTimeout = 300;
initMainImageMouseMove();

document.addEventListener('DOMContentLoaded', () => {
    mainLoading();

    setTimeout(() => {
        initJackpotBox();
        initMoneyNumbers();
    }, loadingTimeout * 2);

    initTopMenuDropdown();

    initCurrentLang();
});

function initCurrentLang() {
    const url = location.href.split('/');
    const langBtn = document.querySelector('.e-toggle-dropdown');
    const langMenu = document.querySelector('.e-dropdown-menu-lang');

    if (url && url[3] && url[3].length === 2) {
        let currentLang = url[3];
        const flagIcon = `flag-icon-${currentLang}`;

        langBtn.querySelector('.dropdown-item-icon').classList.remove('flag-icon-ru');
        langBtn.querySelector('.dropdown-item-icon').classList.add(`flag-icon-${currentLang}`);

        if (currentLang === 'ky') {
            currentLang = 'kg';
        }
        langBtn.querySelector('.dropdown-item-text').innerText = currentLang.toUpperCase();
        langMenu.querySelector(`.${flagIcon}`).parentElement.classList.add('active');

    } else {
        langMenu.querySelector(`.flag-icon-ru`).parentElement.classList.add('active');
    }
}

function initTopMenuDropdown() {
    // Top menu dropdown
    document.querySelector('.e-toggle-dropdown').addEventListener('click', () => {
        document.querySelector('.e-dropdown-menu-lang').classList.toggle('active');
    });

    // Click outside menu
    document.addEventListener('click', function (e) {
        if (
            !document.querySelector('.e-toggle-dropdown').contains(e.target)
            && (!document.querySelector('.e-dropdown-menu-lang').contains(e.target))
        ) {
            document.querySelector('.e-dropdown-menu-lang').classList.remove('active');
        }
    });
}

function  mainLoading() {
    window.addEventListener('load', () => {
        setTimeout(() => document.body.classList.add('loaded'), loadingTimeout)
    });
}

function initMainImageMouseMove() {
    if (window.innerWidth < 1500) {
        return;
    }
    let constrain = 12000;
    let mouseOverContainer = document.body;
    let ex1Layer = document.querySelector(".main-image");

    function transforms(x, y, el) {
        let box = el.getBoundingClientRect();
        let calcX = -(y - box.y - (box.height / 3)) / constrain;
        let calcY = (x - box.x - (box.width / 3)) / constrain;

        return "perspective(100px) "
            + "   rotateX("+ calcX +"deg) "
            + "   rotateY("+ calcY +"deg) ";
    }

    function transformElement(el, xyEl) {
        el.style.transform  = transforms.apply(null, xyEl);
    }

    mouseOverContainer.onmousemove = function(e) {
        let xy = [e.clientX, e.clientY];
        let position = xy.concat([ex1Layer]);

        window.requestAnimationFrame(function(){
            transformElement(ex1Layer, position);
        });
    };

}

function initJackpotBox() {
    const boxBronze = document.querySelectorAll('.e-bronze-box');
    const boxSilver = document.querySelectorAll('.e-silver-box');
    const boxGold = document.querySelectorAll('.e-gold-box');

    let allAnimations = [boxBronze, boxSilver, boxGold];

    if (window.innerWidth < 962) {
        allAnimations = [boxGold, boxSilver, boxBronze];
    }

    const duration = 900;

    allAnimations.forEach((elem, i) => {
        // elem.forEach(el => addTimeout(() => showJackpotBox(el), duration * i))
        elem.forEach(el => showJackpotBox(el))
    })
}

async function showJackpotBox(elem) {
    if (!elem) {
        return;
    }
    const duration = 300;
    const moneyValBox = elem.querySelector('.e-money-val');

    elem.classList.add('visible');
    elem.querySelectorAll('.anim-box')?.forEach(el => el.classList.add('visible'));
    await resolveAnimation(moneyValBox, 0, elem.getAttribute('data-val'), 1);
}

function initMoneyNumbers() {
    const moneyBronze = document.querySelectorAll('.e-money-bronze');
    const moneySilver = document.querySelectorAll('.e-money-silver');
    const moneyGold = document.querySelectorAll('.e-money-gold');

    let allAnimations = [moneyBronze, moneySilver, moneyGold];

    if (window.innerWidth < 962) {
        allAnimations = [moneyGold, moneySilver, moneyBronze];
    }

    const duration = 4910;

    allAnimations.forEach((elem, i) => {
        elem.forEach(async (el) => {
            // setTimeout(async () => {
            //     await resolveAnimation(el, 0, el.getAttribute('data-val'), duration);
            // }, duration * i);
            await resolveAnimation(el, 0, el.getAttribute('data-val'), duration);
        });
    });
}

function addTimeout(callback, time) {
    setTimeout(() => {
        callback();
    }, time)
}
