export function resolveAnimation(obj, start = 0, end, duration = 3000) {
	if (!obj) {
		return;
	}
	return new Promise(resolve => {
		let startTimestamp = null;
		const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const progress = Math.min((timestamp - startTimestamp) / duration, 1);
			obj.innerHTML = Math.floor(progress * (end - start) + start);
			if (progress < 1) {
				window.requestAnimationFrame(step);
				return false;
			} else {
				resolve(true);
			}
		};
		window.requestAnimationFrame(step);
	});
}
